import '@progress/kendo-theme-default/dist/all.css'
import 'symbol-observable'
//import this polyfill for older browser support
//import 'core-js/stable'
/////////////////////////////////////////////////////////////////////
// ATTEMPTED TO ADD IN PETER'S KENDO THEME HERE BUT IT CAUSES WEIRD ERRORS
/////////////////////////////////////////////////////////////////////

import * as Sentry from '@sentry/browser'
import * as AuthConstants from 'auth/constants'
import { Subject } from 'rxjs'
import {withLatestFrom} from 'rxjs/operators'
import * as StoreConstants from 'store/constants'

import {defaultState} from 'defaultState'
import * as History from 'history'
import {isEmpty} from 'lodash'
import * as React from 'react'
import {render} from 'react-dom'
import ReactGA4 from 'react-ga4'
import {Provider} from 'react-redux'
import * as Redux from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import {createEpicMiddleware} from 'redux-observable'
import createSentryMiddleware from 'redux-sentry-middleware'
import 'regenerator-runtime/runtime'
import {from, fromEventPattern} from 'rxjs'
import {filter, take} from 'rxjs/operators'
import serializeError from 'serialize-error'
import UniversalRouter, * as UniversalRouterTypes from 'universal-router'

import './_main.scss'

import {initializeApplication} from './app/actions'
import * as Constants from './constants'
import {RasaContext} from './context'
import * as RasaCookies from './generic/cookies'
import {EntityMetadataClient} from './generic/entityMetadataClient'
import './index.css'
import * as TwoFactorAuthConstants from './pages/2fa-auth/constants'
import { ErrorHandler } from './pages/error/component'
import * as ErrorConstants from './pages/error/constants'
import * as ForgotPasswordConstants from './pages/forgot-password/constants'
import * as HandledErrorConstants from './pages/handled-error/constants'
import * as IntegrationsCallbackConstants from './pages/integrations-callback/constants'
import * as LoginConstants from './pages/login/constants'
import * as ResetPasswordConstants from './pages/reset-password/constants'
import * as SignupConstants from './pages/signup/constants'
import {rootEpic} from './rootEpic'
import {rootReducer} from './rootReducer'
import {routes} from './rootRoutes'
import * as Router from './router'
import { SELECTED_COMMUNITY } from './store/constants'
import { AttributeStore, configureStore } from './store/index'
import {User} from './user/index'

import './kendo_rasa_all.css'
import './kendo_rasa_variables.scss'
import { Stats, StatsType } from './stats'
import { generateGuid } from './utils'

declare const GA4_PROPERTY: string
declare const SENTRY_URL: string
declare const RASA_SELF_SERVICE_API: string
declare const RASA_SHARE_URL: string
declare const RASA_TRACK_URL: string
declare const BITBUCKET_COMMIT: string
declare const DASHBOARD_ENV: string
declare const RASA_HUBSPOT_UI_ENDPOINT: string
declare const google: any

// extending the global window object to contain the GTM dataLayer property
// The GTM dataLayer is where events and variables can be pushed from the UI (using methods in the GTM folder)
declare global {
  interface Window { dataLayer: any[] }
}

window.dataLayer = window.dataLayer || []

const mount = document.createElement('div') as HTMLElement
document.body.appendChild(mount)
const history = History.createBrowserHistory()
// eslint-disable-next-line @typescript-eslint/unbound-method
const history$ = fromEventPattern(history.listen)
const queryParams: URLSearchParams = new URLSearchParams(history.location.search)

const handleQueryParams = () => {
  let reloadNeeded = false
  const cookieName = queryParams.get(RasaCookies.REDIRECT_COOKIE_PARAM)
  if (cookieName) {
    queryParams.delete(RasaCookies.REDIRECT_COOKIE_PARAM)
    reloadNeeded = true
    const cookieValue = RasaCookies.getCookie(cookieName)
    RasaCookies.removeCookie(cookieName)
    window.localStorage.setItem(AuthConstants.RASA_AUTH_TOKEN, cookieValue)
    window.localStorage.removeItem(StoreConstants.SELECTED_COMMUNITY)
  }
  if (queryParams.get(SELECTED_COMMUNITY)) {
    reloadNeeded = true
    window.localStorage.setItem(SELECTED_COMMUNITY, queryParams.get(SELECTED_COMMUNITY))
    queryParams.delete(SELECTED_COMMUNITY)
  }

  if (queryParams.get(LoginConstants.HUBSPOT_REDIRECT_TO_QUERY)) {
    const redirectUrl: string = `${RASA_HUBSPOT_UI_ENDPOINT}${queryParams.get(LoginConstants.HUBSPOT_REDIRECT_TO_QUERY)}`
    window.localStorage.setItem(LoginConstants.HUBSPOT_REDIRECT_TO, redirectUrl)
    queryParams.delete(LoginConstants.HUBSPOT_REDIRECT_TO_QUERY)
  }

  if (reloadNeeded) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const queryString: string = '' + queryParams
    if (isEmpty(queryString)) {
      history.replace(history.location.pathname)
    } else {
      const newUrl: string = history.location.pathname + '?' + queryString
      history.replace(newUrl)
    }
  }
}
handleQueryParams()

sessionStorage.setItem(Constants.SESSION_STORAGE_KEY, generateGuid())

const entityMetadata = new EntityMetadataClient()

class LocalStore implements AttributeStore {
  public get(key: string): string {
    return window.localStorage.getItem(key)
  }

  public set(key: string, value: string) {
    window.localStorage.setItem(key, value)
    return true
  }
}

const user = new User(entityMetadata, new LocalStore())
const dependencies = {
  entityMetadata,
  google,
  history$,
  history,
  localStorage,
  user,
  window,
}
const epicMiddleware = createEpicMiddleware({dependencies})

const action$ = new Subject()
Sentry.init({
  dsn: SENTRY_URL,
  environment: DASHBOARD_ENV,
  release: `dashboard-ui@${BITBUCKET_COMMIT}`,
});

ReactGA4.initialize([
  {
    trackingId: GA4_PROPERTY,
  },
])

const router = new UniversalRouter<Router.RasaRouterContext>(routes, {resolveRoute: Router.onEnterMiddleware})

const actionMiddleware = ({getState}) => (next) => (action) => {
  action$.next(action)
  return next(action)
}

const isTablet = window.matchMedia('(min-width:600px) and (max-width: 1200px)').matches
const isMobile = window.matchMedia('(max-width: 600px)').matches
const isDesktop = !isMobile
const store = configureStore(rootReducer, {
    config: {
      isMobile,
      isDesktop,
      api: RASA_SELF_SERVICE_API,
      ...defaultState,
      auth: {
        token: window.localStorage.getItem(AuthConstants.RASA_AUTH_TOKEN),
        authenticated: false,
      },
      isTablet,
      shareUrl: RASA_SHARE_URL,
      shortenUrl: RASA_TRACK_URL,
    },
  },
  composeWithDevTools(
      Redux.applyMiddleware(
        epicMiddleware,
        createSentryMiddleware(Sentry),
        actionMiddleware,
      ),
    ),
  )

epicMiddleware.run(rootEpic)
const store$ = from(store)
const combined$ = action$.pipe(withLatestFrom(store$))
type ResolveRouter = (resolveContext: UniversalRouterTypes.ResolveContext) =>
  UniversalRouterTypes.Result<React.StatelessComponent>
const resolveRoute: ResolveRouter = (resolveContext) => router.resolve(resolveContext)
  .then((route) => render(
      <Provider store={store}>
        <RasaContext.Provider value={{entityMetadata, user, store$, store, history$, action$, combined$}}>
          <ErrorHandler>
            {route}
          </ErrorHandler>
        </RasaContext.Provider>
      </Provider>,
      mount,
    ),
  )
  .catch((error: Error) => {
    // eslint-disable-next-line no-console
    console.error(error)
    Sentry.captureException(error)
    store.dispatch({type: 'ERROR', error: serializeError(error)})
  })
history$.subscribe(([location]) => resolveRoute({...location, store}))

store$
  .pipe(
    filter(({app}) => app.initialized),
    filter(({auth}: any) => auth.authenticated || history.location.pathname === SignupConstants.SIGNUP_BASE_ROUTE
      || history.location.pathname === ErrorConstants.GENERIC_ERROR_ROUTE
      || history.location.pathname === ForgotPasswordConstants.FORGOT_PASSWORD_BASE_ROUTE
      || history.location.pathname === ForgotPasswordConstants.FORGOT_PASSWORD_SUCCESS_ROUTE
      || history.location.pathname === HandledErrorConstants.HANDLED_ERROR_BASE_ROUTE
      || history.location.pathname === IntegrationsCallbackConstants.INTEGRATIONS_CALLBACK_BASE_ROUTE
      || history.location.pathname === ResetPasswordConstants.RESET_PASSWORD_BASE_ROUTE
      || history.location.pathname === LoginConstants.LOGIN_BASE_ROUTE
      || history.location.pathname === TwoFactorAuthConstants.FA_BASE_ROUTE),
    take(1),
  ).subscribe(() => resolveRoute({...history.location, store}))

store.dispatch(initializeApplication({ location: history.location }))

const logLoadTime = () => {
  setTimeout(() => {
    const navigationEntries =  performance.getEntriesByType('navigation')
    if (navigationEntries && navigationEntries.length && navigationEntries[0].duration) {
      const _stats = Stats.init()
      _stats.setStat(StatsType.LOAD_TIME, navigationEntries[0].duration)
      _stats.log(StatsType.LOAD_TIME)
    } else {
      logLoadTime()
    }
  }, 100)
}

logLoadTime()
