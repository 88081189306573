import { GridCell, GridCellProps } from '@progress/kendo-react-grid'
import {ClickOutside } from 'components/click-outside/component'
import { ReservedCell } from 'components/content-pool/kendocells'
import { preventDefaultThen } from 'components/link/component'
import * as Globals from 'generic/globals'
import { isTruthy } from 'generic/utility'
import React from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import * as TeamCosntants from '../components/settings-editor/constants'

export interface EditCellState {
  drop: boolean,
  showArchiveConfimationModal: boolean,
}

export interface EditCellProps extends GridCellProps {
  applyFilterToAllLabel?: string,
  applyFilterToNoneLabel?: string,
  archiveLabel?: string,
  canAddImage?: boolean,
  canApplyFilterToAll?: boolean,
  canApplyFilterToNone?: boolean,
  canArchive?: boolean,
  canCancelAccount?: any,
  cancelAccountLabel?: string,
  canClone?: boolean,
  canDownload?: boolean,
  canEdit?: boolean,
  canEditSection?: boolean,
  canHide?: boolean,
  canInactive?: boolean,
  canMarkReserved?: boolean,
  canRefresh?: boolean,
  canResendInvite?: boolean,
  canRemoveCancellation?: any,
  canRemoveSuspension?: any,
  canRestore?: boolean,
  canSave?: boolean,
  canSendResetPasswordEmail?: boolean,
  canToggleLoadPosts?: boolean,
  canToggleFullLoad?: boolean,
  canUnsubscribe?: any,
  canSuspendAccount?: any,
  canIsgated?: any;
  confirmOnArchive?: boolean,
  addImageLabel?: string,
  cloneLabel?: string,
  customLabel?: string,
  downloadLabel?: string,
  editLabel?: string,
  showExport?: boolean,
  hideDots?: boolean,
  hideLabel?: string,
  inactiveLabel?: string,
  likeLabel?: string,
  onAddImage?: any,
  onArchive?: any,
  onApplyToAllFilter?: any,
  onClickExport?: any,
  onClone?: any,
  onDownload?: any,
  onConfirmArchive?: any,
  onEdit?: any,
  onHide?: any,
  onInactive?: any,
  onModalClose?: any,
  onRefresh?: any,
  onResendInvite?: any,
  onReserved?: any,
  onSave?: any,
  onSectionEdit?: any,
  onSendResetPasswordEmail?: any,
  onCancel?: any,
  onCancelAccount?: any,
  onCustomClick?: any,
  onRemoveCancellation?: any,
  onRemoveSuspension?: any,
  onSuspendAccount?: any,
  onIsgated?: any,
  refreshLabel?: string,
  removeCancellationLabel?: string,
  removeSuspensionLabel?: string,
  reservedLabel?: string,
  restoreLabel?: string,
  resendInviteLabel?: string,
  resubscribeLabel?: string,
  saveLabel?: string,
  sectionEditLabel?: string,
  sendPasswordResetEmailLabel?: string,
  showCustomLink?: boolean,
  unsubscribeLabel?: string,
  suspendAccount?: string,
  suspendAccountLabel?: string,
  isgated?: string,
  gatedLabel?: string,
  showTransferUserLink?: boolean,
  transferUserLabel?: string,
  onTransferUser?: (event: React.SyntheticEvent, props: EditCellProps) => void,
  onToggleSubscribed?: (event: React.SyntheticEvent, data: any) => void,
}

export class EditableDropdownCell<P extends EditCellProps, S extends EditCellState> extends React.Component<P, S> {
  constructor(p: P, initialState: any = {}) {
    super(p)
    this.state = {
      ...initialState,
      drop: false,
      showArchiveConfimationModal: false,
    }
  }

  public render() {
    return(<td className="edit-cell">
        {this.getConfirmationModalJSX(this.props.dataItem)}
        {this.renderArchiveConfirmationModal()}
        {this.hideDots()}
        {this.props.dataItem.editing && this.props.dataItem.editing.isEditing
        ? <div className="edit-cell-ok-cancel">
            <Button value="Cancel" className="edit-cell-button edit-cell-button-cancel"
              onClick={preventDefaultThen((e) => this.onCancel(e))}>Cancel</Button>
            <Button value="Save" className="edit-cell-button edit-cell-button-ok"
              onClick={preventDefaultThen((e) => this.onSave(e))}>Save</Button>
          </div>
        : <div className="dropdown grid-clickable">
            {!this.props.hideDots &&
            <div onClick={() => this.setState({drop: !this.state.drop})}>
              <div className="little-circle" ></div>
              <div className="little-circle" ></div>
              <div className="little-circle" ></div>
            </div>}
            {!!this.state.drop &&
            <ClickOutside handleClickOutside={() => this.setState({drop: !this.state.drop})}>
              <div className="dropdown-content">
                  { (this.props.onEdit && this.props.canEdit) && !this.props.dataItem.is_archived &&
                    <a onClick={preventDefaultThen((e) => this.onEdit(e))}>
                      {this.props.editLabel || 'Edit'}
                    </a>
                  }

                  { (this.props.canSuspendAccount) ?
                    <a onClick={preventDefaultThen((e) => this.onSuspendAccount(e))}>
                      {this.props.suspendAccountLabel || 'Suspend Account'}
                    </a>
                    : null
                  }

                  { (this.props.canRemoveSuspension) ?
                    <a onClick={preventDefaultThen((e) => this.onRemoveSuspension(e))}>
                      {this.props.removeSuspensionLabel || 'Remove Suspension'}
                    </a>
                    : null
                  }

                  { (this.props.canCancelAccount) ?
                  <a onClick={preventDefaultThen((e) => this.onCancelAccount(e))}>
                      {this.props.cancelAccountLabel || 'Cancel Account'}
                    </a>
                    : null
                  }

                  { (this.props.canRemoveCancellation) ?
                    <a onClick={preventDefaultThen((e) => this.onRemoveCancellation(e))}>
                      {this.props.removeCancellationLabel || 'Remove Cancellation'}
                    </a>
                    : null
                  }

                  { (this.props.canAddImage && this.props.dataItem.image_url
                    && this.props.dataItem.image_url.length > 0) ?
                    <a onClick={preventDefaultThen((e) => this.onAddImage(e))}>
                        {this.props.addImageLabel || 'Edit Image'}
                      </a>
                      : null
                  }
                  { (this.props.canAddImage && !this.props.dataItem.image_url) ?
                    <a onClick={preventDefaultThen((e) => this.onAddImage(e))}>
                        {this.props.addImageLabel || 'Add Image'}
                      </a>
                      : null
                  }

                  { (this.props.canIsgated && !this.props.dataItem.source_is_gated) ?
                  <a onClick={preventDefaultThen((e) => this.onIsgated(e))}>
                      {this.props.gatedLabel || 'Mark gated'}
                    </a>
                    : null
                  }
                  { (this.props.canIsgated && this.props.dataItem.source_is_gated) ?
                  <a onClick={preventDefaultThen((e) => this.onIsgated(e))}>
                      {this.props.gatedLabel || 'Remove gated'}
                    </a>
                    : null
                  }

                  { (this.props.canToggleLoadPosts) ?
                    <a onClick={preventDefaultThen((e) => this.toggleLoadPosts(e))}>
                      {isTruthy(this.props.dataItem.load_posts) ? 'Turn off post load' : 'Turn on post load'}
                    </a>
                    : null
                  }
                  { (this.props.canToggleFullLoad) ?
                    <a onClick={preventDefaultThen((e) => this.toggleFullloads(e))}>
                      {isTruthy(this.props.dataItem.is_full_load) ? 'Turn off full load' : 'Turn on full load'}
                    </a>
                    : null
                  }
                  { (this.props.canUnsubscribe && this.props.dataItem.is_subscribed) ?
                    <a onClick={preventDefaultThen((e) => this.toggleSubscribed(e))}>
                      {this.props.unsubscribeLabel || 'Unsubscribe'}
                    </a>
                    : null
                  }
                  { (this.props.canUnsubscribe && !this.props.dataItem.is_subscribed) ?
                    <a onClick={preventDefaultThen((e) => this.toggleSubscribed(e))}>
                      {this.props.resubscribeLabel || 'Resubscribe'}
                    </a>
                    : null
                  }
                  { this.props.canRefresh && !isTruthy(this.props.dataItem.is_archived) &&
                    <a onClick={preventDefaultThen((e) => this.onRefresh(e))}>
                      {this.props.refreshLabel || 'Refresh'}
                    </a>
                  }
                  { this.props.canArchive && !isTruthy(this.props.dataItem.is_archived) &&
                    <a onClick={preventDefaultThen((e) => this.onArchive(e))}>
                      {this.props.archiveLabel || 'Archive'}
                    </a>
                  }
                  { this.props.canRestore && isTruthy(this.props.dataItem.is_archived) &&
                    <a onClick={preventDefaultThen((e) => this.onRestore(e))}>
                      {this.props.restoreLabel || 'Restore'}
                    </a>
                  }
                  { this.props.canDownload  &&
                    <a onClick={preventDefaultThen((e) => this.onDownload(e))}>
                      {this.props.downloadLabel || 'Download'}
                    </a>
                  }
                  { this.props.canEditSection  &&
                    <a onClick={preventDefaultThen((e) => this.onSectionEdit(e))}>
                      <img src={Globals.CONTENT_POOL_OPTION_IMG} className="panel-generic-item-icon"/>
                      {this.props.sectionEditLabel || 'Edit Section'}
                    </a>
                  }
                  { this.props.canMarkReserved &&
                    <ReservedCell dataItem={this.props.dataItem} onChange={(e) => this.onReserved(e)} />
                  }
                  { this.props.canInactive && isTruthy(this.props.dataItem.is_active) &&
                    <a onClick={preventDefaultThen((e) => this.onInactive(e))}>
                      {this.props.inactiveLabel || 'Mark Inactive'}
                    </a>
                  }
                  { this.props.canApplyFilterToAll &&
                    <a onClick={preventDefaultThen((e) => this.onApplyBulkFilter(true))}>
                      {this.props.applyFilterToAllLabel || 'Apply to All'}
                    </a>
                  }
                  { this.props.canApplyFilterToNone &&
                    <a onClick={preventDefaultThen((e) => this.onApplyBulkFilter(false))}>
                      {this.props.applyFilterToNoneLabel || 'Apply to None'}
                    </a>
                  }
                  { this.props.canResendInvite &&
                    <a onClick={preventDefaultThen((e) => this.onResendInvite(e))}>
                      {this.props.resendInviteLabel || 'Resend Invite'}
                    </a>
                  }
                {
                  this.props.canSendResetPasswordEmail &&
                  this.props.dataItem.account_type === TeamCosntants.MEMBER_STATUS_ACCOUNT &&
                  <a onClick={preventDefaultThen((e) => this.onSendResetPasswordEmail())}>
                    {this.props.sendPasswordResetEmailLabel || 'Send Reset Password Email'}
                  </a>
                }
                {this.props.canSave
                  &&
                  <a onClick={preventDefaultThen((e) => this.onSave(e))}>
                  <i className="fa fa-plus"></i>
                      {this.props.saveLabel || 'Save'}
                    </a>
                  }
                  { this.props.canHide && isTruthy(this.props.dataItem.is_content_guide_visible) &&
                    <a onClick={preventDefaultThen((e) => this.onHide(e))}>{this.props.hideLabel || 'Delete'}</a>
                  }
                  { this.props.showCustomLink
                    &&
                    <a onClick={preventDefaultThen((e) => this.onCustomClick(e))}>
                      {this.props.customLabel || 'Custom'}
                    </a>
                  }
                  { this.props.onClone &&
                    <a onClick={preventDefaultThen((e) => this.onClone(e))}>
                      {this.props.cloneLabel || 'Clone'}
                    </a>
                  }
                  { this.props.showExport &&
                    <a onClick={preventDefaultThen((e) => this.onClickExport(e))}>
                      Export
                    </a>
                  }
                  { this.props.showTransferUserLink &&
                    <a onClick={preventDefaultThen((e: React.SyntheticEvent) => this.onTransferUser(e))}>
                      {this.props.transferUserLabel || 'Transfer user'}
                    </a>
                  }
                </div>
            </ClickOutside>}
        </div>
      }
    </td>)
  }

  public getConfirmationModalJSX(dataItem: any) {
   return  null
  }

  protected hideDots() {
    return null
  }

  private renderArchiveConfirmationModal(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.showArchiveConfimationModal}>
          <ModalBody className="delete-modal">
            <div>
              <h4>Are You Sure?</h4>
              <p>Are you sure you want to delete this record?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={(e) => this.archiveRecord(e)}>
              Yes
            </Button>
            <Button onClick={this.closeArchiveConfimationModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  protected onArchive(event: any) {
    if (this.props.confirmOnArchive) {
      this.setState({showArchiveConfimationModal: true})
    } else {
      this.archiveRecord(event)
    }
  }

  protected onSuspendAccount(event: any) {
    if (this.props.onSuspendAccount) {
      this.props.onSuspendAccount(this.props.dataItem)
    } else {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: event,
        field: 'community_account_suspended',
        value: true,
      })
    }
    this.setState({drop: !this.state.drop})
  }

  protected onRemoveSuspension(event: any) {
    if (this.props.onRemoveSuspension) {
      this.props.onRemoveSuspension(this.props.dataItem)
    } else {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: event,
        field: 'community_account_suspended',
        value: false,
      })
    }
    this.setState({drop: !this.state.drop})
  }

  protected onCancelAccount(event: any) {
    if (this.props.onCancelAccount) {
      this.props.onCancelAccount(this.props.dataItem)
    } else {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: event,
        field: 'account_is_cancelled',
        value: true,
      })
    }
    this.setState({drop: !this.state.drop})
  }

  protected onRemoveCancellation(event: any) {
    if (this.props.onRemoveCancellation) {
      this.props.onRemoveCancellation(this.props.dataItem)
    } else {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: event,
        field: 'account_is_cancelled',
        value: false,
      })
    }
    this.setState({drop: !this.state.drop})
  }

  protected onIsgated(event: any) {
    if ( this.props.onIsgated ) {
      this.props.onIsgated(this.props)
    }
    this.setState({ drop: !this.state.drop })
  }

  protected onAddImage(event: any) {
    if ( this.props.onAddImage ) {
      this.props.onAddImage(this.props)
    }
    this.setState({ drop: !this.state.drop })
  }

  private onEdit(event: any) {
    if ( this.props.onEdit ) {
      this.props.onEdit(this.props.dataItem)
    } else {
      this.props.onChange(event)
    }
    this.setState({ drop: !this.state.drop })
  }

  private onRefresh(event: any) {
    if ( this.props.onRefresh ) {
      this.props.onRefresh(this.props.dataItem)
    }
    this.setState({ drop: !this.state.drop })
  }

  private onClone(event: any) {
    const change: any = {
      dataItem: this.props.dataItem,
      syntheticEvent: event,
      field: event.field,
      value: event.value,
    }
    if ( this.props.onClone ) {
      this.props.onClone(change)
    }
    this.setState({ drop: !this.state.drop })
  }

  private onDownload(event: any) {
    if ( this.props.onDownload ) {
      this.props.onDownload(this.props.dataItem)
    }
    this.setState({ drop: !this.state.drop })
  }

  private onClickExport(event: any) {
    if ( this.props.onClickExport ) {
      this.props.onClickExport(this.props.dataItem)
    }
    this.setState({ drop: !this.state.drop })
  }

  private onSectionEdit(event: any) {
    if ( this.props.onSectionEdit ) {
      this.props.onSectionEdit(this.props)
    }
    this.setState({ drop: !this.state.drop })
  }

  private onReserved(event: any) {
    if ( this.props.onReserved ) {
      this.props.onReserved(this.props, event)
    } else {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: event,
        field: event.field,
        value: event.value,
      })
    }
    this.setState({ drop: !this.state.drop })
  }

  private onInactive(event: any) {
    if (this.props.onInactive) {
      this.props.onInactive(event, this.props)
    } else {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: event,
        field: 'is_active',
        value: false,
      })
    }
    this.setState({ drop: !this.state.drop })
  }

  private onRestore(event: any) {
    this.props.onChange({
      dataItem: this.props.dataItem,
      syntheticEvent: event,
      field: 'is_archived',
      value: false,
    })
    this.setState({ drop: !this.state.drop })
  }

  private toggleLoadPosts = (event: any) => {
    const newValue: boolean = !this.props.dataItem.load_posts
    if (this.props.onChange) {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: event,
        field: 'load_posts',
        value: newValue,
      })
    }
    this.setState({ drop: !this.state.drop })
  }

  private toggleFullloads = (event: any) => {
    const newValue: boolean = !this.props.dataItem.is_full_load
    if (this.props.onChange) {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: event,
        field: 'is_full_load',
        value: newValue,
      })
    }
    this.setState({ drop: !this.state.drop })
  }

  private toggleSubscribed = (event: any) => {
    const newIsSubscribed: boolean = !this.props.dataItem.is_subscribed
    if(this.props.onToggleSubscribed !== undefined){
      return this.props.onToggleSubscribed(event, this.props.dataItem)
    }
    if (this.props.onChange) {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: event,
        field: 'is_subscribed',
        value: newIsSubscribed,
      })
    }
    this.setState({ drop: !this.state.drop })
  }

  private onApplyBulkFilter(isActive) {
    this.props.onApplyToAllFilter(this.props.dataItem.id, isActive)
    this.setState({ drop: !this.state.drop })
  }

  private onResendInvite(event: any) {
    this.props.onResendInvite(event, this.props)
    this.setState({ drop: !this.state.drop })
  }

  private onSave(event: any) {
    if ( this.props.onSave ) {
      this.props.onSave(this.props.dataItem)
    } else {
      this.props.onChange(event)
    }
    this.setState({ drop: !this.state.drop })
  }

  private onSendResetPasswordEmail() {
    this.props.onSendResetPasswordEmail(this.props.dataItem)
    this.setState({ drop: !this.state.drop })
  }

  private onCancel(event: any) {
    if ( this.props.onCancel ) {
      this.props.onCancel(this.props.dataItem)
    } else {
      this.props.onChange(event)
    }
    this.setState({ drop: !this.state.drop })
  }

  private onHide(event: any) {
    if (this.props.onHide) {
      this.props.onHide(this.props.dataItem)
    }
    this.setState({ drop: !this.state.drop })
  }
  private onCustomClick(event: any) {
    if (this.props.onCustomClick) {
      this.props.onCustomClick(this.props.dataItem)
    }
    this.setState({ drop: !this.state.drop })
  }

  private onTransferUser(event: React.SyntheticEvent) {
    if(this.props.onTransferUser) {
      this.props.onTransferUser(event, this.props)
      this.setState({drop: !this.state.drop})
    }
  }

  private archiveRecord(event: any) {
    if (this.props.onArchive) {
      this.props.onArchive(this.props, event)
    } else {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: event,
        field: 'is_archived',
        value: true,
      })
    }
    this.setState({drop: !this.state.drop, showArchiveConfimationModal: false,})
  }

  private closeArchiveConfimationModal() {
    this.setState({
      showArchiveConfimationModal: false,
    })
  }
}

export class EditCell extends GridCell {

  constructor(props: GridCellProps) {
    super(props)
  }

  public render = () => <EditableDropdownCell {...this.props}/>
}
