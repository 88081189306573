import { DropdownOption } from 'components/dropdown/component'
import * as DateFormat from 'date-fns'
import { isTruthy } from 'generic/utility'
import { formatDateForES } from 'shared_server_client/dates'

export enum IndexName {
  COMMUNITY_PERSON_ACTION_ROLLUP = 'community-person-action-rollup',
  EVENTS = 'email-events',
  POSTS = 'mrd.posts',
  TAGS = 'tags',
  MESSAGE_RUN_DETAIL = 'mrd.message-run-details',
  URL_REQUESTS = 'url-requests',
}

const DateDescriptors = {
  LastMonthEnd: 'now/M',
  LastMonthStart: 'now-1M/M',
  LastThreeMonthStart: 'now-3M/M',
  LastQuarter: 'now-1q',
  LastMonthWeekStart: 'now-1M/w',
  LastWeekEnd: 'now/w',
  LastWeekStart: 'now-1w/w',
  Now: 'now',
  OneDayAgo: 'now-1d',
  PastDayStart: 'now-1d/d',
  PastMonthStart: 'now-1M/d',
  PastWeekStart: 'now-1w/d',
  PastYearStart: 'now-1y/d',
  TodayStart: 'now/d',
  YesterdayEnd: 'now-1d/d+23h+59m+59s',
}

const DELIMITER = '|'
export const LASTQUARTER = 'Last Quarter'

export const DateRanges = {
  LastMonth: `${DateDescriptors.LastMonthStart}${DELIMITER}${DateDescriptors.LastMonthEnd}`,
  LastThreeMonth: `${DateDescriptors.LastThreeMonthStart}${DELIMITER}${DateDescriptors.LastMonthEnd}`,
  LastQuarter: `${DateDescriptors.LastQuarter}${DELIMITER}${DateDescriptors.LastQuarter}`,
  LastWeek: `${DateDescriptors.LastWeekStart}${DELIMITER}${DateDescriptors.LastWeekEnd}`,
  PastDay: `${DateDescriptors.OneDayAgo}${DELIMITER}${DateDescriptors.Now}`,
  PastMonth: `${DateDescriptors.PastMonthStart}${DELIMITER}${DateDescriptors.YesterdayEnd}`,
  PastWeek: `${DateDescriptors.PastWeekStart}${DELIMITER}${DateDescriptors.YesterdayEnd}`,
  PastYear: `${DateDescriptors.PastYearStart}${DELIMITER}${DateDescriptors.YesterdayEnd}`,
  Today: `${DateDescriptors.TodayStart}${DELIMITER}${DateDescriptors.Now}`,
  Yesterday: `${DateDescriptors.PastDayStart}${DELIMITER}${DateDescriptors.YesterdayEnd}`,
  WeeklyAverage: `${DateDescriptors.LastMonthWeekStart}${DELIMITER}${DateDescriptors.LastWeekEnd}`,
}

export const DateRangesAsDropdownOptions: DropdownOption[] = [
  {key: '1', value: DateRanges.Yesterday, description: 'Yesterday'},
  {key: '2', value: DateRanges.Today, description: 'Today'},
  {key: '3', value: DateRanges.PastWeek, description: 'Last 7 Days'},
  {key: '4', value: DateRanges.LastWeek, description: 'Last Week'},
  {key: '5', value: DateRanges.PastMonth, description: 'Last ~30 Days'},
  {key: '6', value: DateRanges.LastMonth, description: 'Last Month'},
  {key: '8', value: DateRanges.LastThreeMonth, description: 'Last 3 Months'},
  {key: '9', value: DateRanges.LastQuarter, description: LASTQUARTER},
  {key: '7', value: '', description: 'Custom Date Range'},
]

export const AggregateIntervalOptions: DropdownOption[] = [
  {key: '1', value: 'day', description: 'Group by day'},
  {key: '2', value: 'week', description: 'Group by week'},
  {key: '3', value: 'month', description: 'Group by month'},
]

export const AggregateMetricOptions: DropdownOption[] = [
  {key: '1', value: 'click', description: 'Clicks'},
  {key: '2', value: 'open', description: 'Opens'},
  {key: '3', value: 'delivered', description: 'Deliveries'},
]

export const AggregateSegmentationStrategyOptions: DropdownOption[] = [
  {key: '1', value: 'email_domain', description: 'Email Domain'},
  {key: '2', value: 'mx_domain', description: 'Mailbox Provider'},
]

export const AggregateSegmentCodeOptions: DropdownOption[] = [
  {key: '1', value: 'community_partner_code', description: 'Segment'},
]

export enum AggregationType {
  cardinality = 'cardinality',
  date_histogram = 'date_histogram',
  range = 'range',
  terms = 'terms',
}

export enum FilterType {
  exists = 'exists',
  match = 'match',
  term = 'term',
  terms = 'terms',
  range = 'range',
}

export interface DateRangeFilter {
  gte?: string,
  lt?: string,
  time_zone?: string,
}

export const isMessageSendDateTimeAvailable = (dateRange: string, useTimezoneForAnalytics: any = 0) => {
  if (useTimezoneForAnalytics === -1) {
    return false
  } else if (isTruthy(useTimezoneForAnalytics)) {
    return true
  }
  try
  {
    const normalDateRange = esToNormalDateRange(dateRange).split('|')
    if (new Date(normalDateRange[0]).getFullYear() >= 2025) {
      return true
    }
  }
  catch(err) {
    return false
  }
  return false
}

export const getMessageSendDateFilterField = (dateRange: string, useTimezoneForAnalytics: any = 0) => {
  return isMessageSendDateTimeAvailable(dateRange, useTimezoneForAnalytics) ? 'message_send_datetime' : 'message_send_date'
}
export const appendTimezoneIfAvailable = (extra: any, dateRange: string, time_zone: string, useTimezoneForAnalytics: any = 0) => {
  if (isMessageSendDateTimeAvailable(dateRange, useTimezoneForAnalytics)) {
    extra = {
      ...extra,
      time_zone,
    }
  }
  return extra
}

export const toFilter = (dateRange: string, time_zone: string = null, useTimezoneForAnalytics: any = 0): DateRangeFilter => {
  const parts = dateRange.split(DELIMITER)
  if (time_zone && isMessageSendDateTimeAvailable(dateRange, useTimezoneForAnalytics)) {
    return {
      gte: parts[0],
      lt: parts[1],
      time_zone,
    }
  } else {
    return {
      gte: parts[0],
      lt: parts[1],
    }
  }
}

export const toTimestampFilter = (dateRange: string): DateRangeFilter => {
  const normalDateRange = esToNormalDateRange(dateRange)
  const gte = (new Date(normalDateRange.split('|')[0] + 'T00:00:00Z')).getTime() / 1000
  const lt = (new Date(normalDateRange.split('|')[1] + 'T23:59:59Z')).getTime() / 1000
  return {
    gte: gte.toString(),
    lt: lt.toString(),
  }
}

export const esToNormalDateRange = (dateRange: string): string => {
  let newDateRange = dateRange
  if (newDateRange.indexOf(DateDescriptors.LastMonthEnd) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.LastMonthEnd,
      formatDateForES(DateFormat.endOfMonth(DateFormat.subMonths(new Date(), 1))))
  }
  if (newDateRange.indexOf(DateDescriptors.LastMonthStart) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.LastMonthStart,
      formatDateForES(DateFormat.startOfMonth(DateFormat.subMonths(new Date(), 1))))
  }
  if (newDateRange.indexOf(DateDescriptors.LastWeekEnd) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.LastWeekEnd,
      formatDateForES(DateFormat.endOfWeek(DateFormat.subWeeks(new Date(), 1))))
  }
  if (newDateRange.indexOf(DateDescriptors.LastWeekStart) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.LastWeekStart,
      formatDateForES(DateFormat.startOfWeek(DateFormat.subWeeks(new Date(), 1))))
  }
  if (newDateRange.indexOf(DateDescriptors.YesterdayEnd) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.YesterdayEnd,
      formatDateForES(DateFormat.endOfDay(DateFormat.subDays(new Date(), 1))))
  }
  if (newDateRange.indexOf(DateDescriptors.PastDayStart) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.PastDayStart,
      formatDateForES(DateFormat.startOfDay(DateFormat.subDays(new Date(), 1))))
  }
  if (newDateRange.indexOf(DateDescriptors.OneDayAgo) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.OneDayAgo,
      formatDateForES(DateFormat.subDays(new Date(), 1)))
  }
  if (newDateRange.indexOf(DateDescriptors.PastMonthStart) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.PastMonthStart,
      formatDateForES(DateFormat.subDays(new Date(), 30)))
  }
  if (newDateRange.indexOf(DateDescriptors.PastWeekStart) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.PastWeekStart,
      formatDateForES(DateFormat.subDays(new Date(), 7)))
  }
  if (newDateRange.indexOf(DateDescriptors.PastYearStart) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.PastMonthStart,
      formatDateForES(DateFormat.subDays(new Date(), 365)))
  }
  if (newDateRange.indexOf(DateDescriptors.TodayStart) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.TodayStart,
      formatDateForES(DateFormat.startOfDay(new Date())))
  }
  if (newDateRange.indexOf(DateDescriptors.LastMonthWeekStart) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.LastMonthWeekStart,
      formatDateForES(DateFormat.startOfWeek(DateFormat.subMonths(new Date(), 1))))
  }
  if (newDateRange.indexOf(DateDescriptors.Now) > -1) {
    newDateRange = newDateRange.replace(DateDescriptors.Now,
      formatDateForES((new Date())))
  }
  return newDateRange
}

export const SuspectFilterDropdownOptions: DropdownOption[] = [
  { key: '1', value: 'all_clicks', description: 'All Clicks' },
  { key: '2', value: 'real_clicks', description: 'Without Suspect Clicks' },
]

export const SizeFilterDropdownOptions: DropdownOption[] = [
  { key: '1', value: '100', description: 'Top 100' },
  { key: '2', value: '10000', description: 'All' },
]

export const SuspectFilterDropdownOptionsForSegments: DropdownOption[] = [
  { key: '1', value: 'all_clicks', description: 'All Clicks' },
  { key: '2', value: 'real_clicks', description: 'Without Suspect Clicks' },
  { key: '3', value: 'suspect_clicks', description: 'Only Suspect Clicks' },
]

export const SuspectFilterDropdownOptionsForTopic: DropdownOption[] = [
  { key: '1', value: 'all', description: 'All Clicks' },
  { key: '2', value: 'suspect_clicks', description: 'Only Suspect Clicks' },
]

export const SourceNameOptions: DropdownOption[] = [
  {
    key: '1',
    value: 'source_name',
    description: 'Source Name',
  },
  {
    key: '2',
    value: 'site_name',
    description: 'Publisher',
  },
]

export const dimensionsFiltersDropdownOptions: DropdownOption[] = [
  {key: '1', value: '1', description: 'Top 1'},
  {key: '2', value: '3', description: 'Top 3'},
  {key: '3', value: '5', description: 'Top 5'},
  {key: '4', value: '7', description: 'Top 7'},
  {key: '5', value: '10', description: 'Top 10'},
]
