// NOTE: This file should be syncrhonized between onboarding-ui and dashboard-ui
export const REDIRECT_COOKIE_PARAM = 'rsc'

export interface RasaCookie {
  maxAge: number
  name: string,
  subdomain?: string,
  value: string,
}

const TOKEN_DELIM = '; '
interface CookiePart {
  key: string,
  value: any,
}

export const setCookie = (cookie: RasaCookie) => {
  const topDomain = window.location.host.includes('localhost') ? 'localhost' : 'rasa.io'
  const domain = cookie.subdomain ? `${cookie.subdomain}.${topDomain}` : topDomain
  const parts: CookiePart[] = [
    {
      key: cookie.name,
      value: cookie.value,
    },
    {
      key: 'domain',
      value: domain,
    },
    {
      key: 'max-age',
      value: cookie.maxAge > 0 ? cookie.maxAge : null,
    },
  ]
  const cookieString = parts.filter((p: CookiePart) => p.value)
    .map((p: CookiePart) => `${p.key}=${p.value}`)
    .join(TOKEN_DELIM)
  document.cookie = cookieString
}

export const getCookie = (name: string): string => {
  try {
    const cookie = document.cookie.split(TOKEN_DELIM).find((s) => s.startsWith(`${name}=`))
    if ( cookie ) {
      return cookie.split('=')[1]
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error processing a cookie ', err)
  }
  return null
}

export const removeCookie = (name: string) => {
  setCookie({
    maxAge: -1,
    name,
    value: '',
  })
}
